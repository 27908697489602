
* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
}

// particles js styles

#particles-js {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

:root {
    --bg-color: #1f242d;
    --bg-color2: #191d25;
    --second-bg-color: #323946;
    --text-color: #fff;
    --main-color: #ff5500;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    background: var(--bg-color);
    color: var(--text-color);
}

.header {
    transition: .5s ease;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 9%;
    background: var(--bg-color2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    .logo {
        font-size: 2.5rem;
        color: var(--text-color);
        font-weight: 600;
        cursor: default;
    }
    
    h1 {
        color: #ffa273;
    }

    .navbar {
        transition: .5s ease;
        cursor: pointer;
        a {
            font-size: 1.7rem;
            color: var(--text-color);
            margin-left: 4rem;
            transition: .3s;
            &:hover, &.active {
                color: var(--main-color);
            }
        }
    }
    #menu-icon {
        z-index: 10;
        font-size: 3.6rem;
        color: var(--text-color);
        display: none;
    }
}

.logo-nav {
    width: 6rem;
}

.header.sticky {
    border-bottom: .06rem solid rgb(255, 255, 255);
}

section {
    min-height: 100vh;
    padding: 10rem 9% 2rem;
}

.domov {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;  /* zmenit na :center pri mobilnej responzivite lebo vo videu to ma na :center ale ma iny obrazok*/
    align-items: center;
    .domov-content {
        h1 {
            font-size: 5.6rem;
            font-weight: 9y00;
            line-height: 1.3;
            margin-bottom: 0;
            &:nth-of-type(1) {
                color: var(--main-color); 
                text-decoration: underline #2d96ff ; 
            }
        }
        h3 {
            font-size: 3.2rem;
            font-weight: 700;
            &:nth-of-type(2) {
                margin-bottom: 2rem;
            }
            
        }
        p {
            margin-top: 6rem;
            font-size: 2rem;
        }
        .social-media {
            a {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 4rem;
                height: 4rem;
                background: transparent;
                border: .2rem solid var(--main-color);
                border-radius: 50%;
                font-size: 2rem;
                color: var(--main-color);
                margin: 3rem 1.5rem 3rem 0;
                transition: .5s ease;
                &:hover {
                    background: var(--main-color);
                    color: var(--second-bg-color);
                    box-shadow: 0 0 1rem var(--main-color);
                }
            }
        }
    }
    .domov-img {
        img {
            width: 28vw;
        }
    }
}

.btn {
    display: inline-block;
    padding: 1rem 2.8rem;
    background: var(--main-color);
    border-radius: 4rem;
    box-shadow: 0 0 1.2rem var(--main-color);
    font-size: 1.6rem;
    color: var(--second-bg-color);
    letter-spacing: .1rem;
    font-weight: 600;
    transition: .5s ease;
    &:hover {
        box-shadow: none;
    }
}

.onas {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background: var(--second-bg-color);
    .about-img {
        img {
            width: 30vw;
        }
    }
    .onas-content {
        margin-left: 4rem;    /* pridane naviac, on to v kode nema takze to moze robit neskor problem (odstranit) */
        h2 {
            text-align: left;
            line-height: 1.2;
        }
        h3 {
            margin-top: 1rem;
            font-size: 2.6rem;
            text-decoration: underline #2d96ff;
        }
        p {
            font-size: 1.6rem;
            margin: 2rem 0 3rem;
            line-height: 1.6;
        }
    }
    span {
        color: #ff8e55;
    }
}

.heading {
    font-size: 4.5rem;
}

span {
    color: #ff8e55;
}

.sluzby {
    h2 {
        margin-bottom: 5rem;
    }
    .sluzby-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
        .sluzby-box {
            flex: 1 1 30rem;
            background: var(--second-bg-color);
            padding: 3rem 2rem 4rem;
            border-radius: 2rem;
            text-align: center;
            transition: .5s ease;
            min-height: 300px;
            max-height: 490px;
            i {
                font-size: 6rem;
                color: var(--main-color);
            }
            h3 {
                font-weight: 700;
                font-size: 2.6rem;
            }
            p {
                font-size: 1.6rem;
                margin: 1rem 0 1rem;
                font-weight: 100;
            }
            &:hover {
                border-width: .3rem;
                transform: scale(1.03);
            }
            h2 {
                margin: 10px 0;
            }
            .btn {
                margin-top: 15px;
            }
        }
        .box1 {
            h3 {
                color: #e6a31c;
            }
            border: .2rem solid #e6a31c;
            a {
                color: rgb(255, 255, 255);
            }
            &:hover {
                box-shadow: 0 0 1.2rem #f1ac21;
            }
        }
        .box2 {
            h3 {
                color: #ffbc9b;
            }
            border: .2rem solid #d1d1d1;
            a {
                color: rgb(255, 255, 255);
            }
            &:hover {
                box-shadow: 0 0 1.2rem #e0e0e0;
            }
        }
        .box3 {
            h3 {
                color: #2edbdb;
            }
            border: .2rem solid #2edbdb;
            a {
                color: rgb(255, 255, 255);
            }
            &:hover {
                box-shadow: 0 0 1.2rem #30eaea;
            }
        }
    }
}

.galeria {
    background: var(--second-bg-color);
    h2 {
        margin-bottom: 4rem;
    }
}

.galeria-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 2.5rem;
    .galeria-box {
        position: relative;
        border-radius: 1rem;
        box-shadow: 0 0 1rem var(--bg-color);
        overflow: hidden;
        display: flex;
    }
}

.galeria-box img {
    width: 100%;
    transition: .5s ease;
}

.galeria-box:hover img {
    transform: scale(1.1);
}

.galeria-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, .1), var(--main-color));
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 4rem;
    flex-direction: column;
    transform: translateY(100%);
    transition: .5s ease;
    h4 {
        font-size: 3rem;
    }
    p {
        font-size: 1.6rem;
        margin: .3rem 0 1rem;
    }
    a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        height: 5rem;
        background: var(--text-color);
        border-radius: 50%;
        i {
            font-size: 2rem;
            color: var(--second-bg-color);
        }
    }
}

.galeria-box:hover .galeria-layer {
    transform: translateY(0);
}

.kontakt {
    #dopln, #balik{
        background-color:#fa7a3a;
    }
    h2 {
        text-align: center;
        margin-bottom: 3rem;
    }
    form {
        max-width: 70rem;
        margin: 1rem auto;
        text-align: center;
        margin-bottom: 3rem;
        .input-box {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
    .btn {
        margin-top: 2rem;
        cursor: pointer;
    }
    select {
        width: 49%;
        padding: 1.5rem;
        font-size: 1.6rem;
        color: var(--text-color);
        background: var(--second-bg-color);
        border-radius: .8rem;
        margin: .7rem 0;
    }
}

.kontakt form .input-box input,
.kontakt form textarea {
    width: 100%;
    padding: 1.5rem;
    font-size: 1.6rem;
    color: var(--text-color);
    background: var(--second-bg-color);
    border-radius: .8rem;
    margin: .7rem 0;
}


.menu {
    color:yellow;
}

.slide {
    color: red;
}

.green {
    color: green;
}

.kontakt form .input-box input {
    width: 100%;
    min-height: 70px;
}

.kontakt form textarea {
    resize: none;
    max-height: 200px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 9%;
    background: var(--second-bg-color);
    .footer-text p {
        font-size: 1.6rem;
    }

    .footer-iconTop a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: .8rem;
        background: var(--main-color);
        border-radius: 50%;
        transition: .5s ease;
        i {
            font-size: 2.4rem;
            color: var(--second-bg-color);
        }
    }

    .footer-iconTop a:hover {
        box-shadow: 0 0 1rem var(--main-color);
    }
}

// BREAKPOINTY

@media (max-width: 1200px){
    html {
        font-size: 55%;
    }
}

@media (max-width: 991px){
    .onas-content {
        max-width: 80%;
    }
    .header {
        padding: 2rem 3%;
    }
    section {
        padding: 10rem 3% 2rem;
    }
    .sluzby {
        padding-bottom: 7rem;
    }
    .galeria {
        padding-bottom: 7rem;
    }
    .kontakt {
        min-height: auto;
    }
    .footer {
        padding: 2rem 3%;
    }
}

@media (max-width: 768px){
    .logo {
        font-size: 2rem!important;
    }

    #menu-icon {
        display: block!important;
    }

    .navbar {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 1rem 3%;
        background: var(--bg-color);
        border-top: .1rem solid rgba(0, 0, 0, .2);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
        display: none;
        transition: .5s ease;
        a {
            display: block;
            font-size: 2rem;
            margin: 3rem 0;
        }
    }
    
    .active {
        display: block;
        transition: .5s ease;
    }
    
    .domov {
        flex-direction: column;
    }
    .domov-content {
        h3 {
            font-size: 2.6rem;
        }
        h1 {
            font-size: 5rem;
        }
    }
    .domov-img img {
        margin-top: 10rem;
        width: 60vw!important;
    }
    .onas {
        flex-direction: column-reverse;
    }
    .about-img img {
        width: 70vw;
        margin-top: 4rem;
    }
    .sluzby h2 {
        margin-bottom: 3rem;
    }
    .galeria h2 {
        margin-bottom: 3rem;
    }
    .galeria-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 617px){
    .galeria-container {
        grid-template-columns: 1fr;
    }
    .galeria-box {
        margin: 0 5%;
    }
    footer {
        flex-direction: column-reverse;
        a {
            margin-bottom: 2rem;
        }
    }
}

@media (max-width: 450px){
    body {
        overflow-x: hidden!important;
        width: 100%!important;
    }
    html {
        font-size: 50%;
    }
    .kontakt form .input-box input {
        width: 100%;
    }
    select {
        width: 100%!important;
    }
    .onas-content {
        max-width: 77%;
    }
}

@media (max-width: 365px){
    .domov-img img {
        width: 90vw;
    }
    .onas img {
        width: 90vw;
    }
}